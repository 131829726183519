import React from 'react';

import { Link } from "gatsby-plugin-intl"

import styles from './boxService.module.css';
import { IoOptionsOutline, IoTrendingUp, IoWalletOutline, IoChevronUpCircleOutline, IoCellularOutline, IoPieChartOutline } from "react-icons/io5";

function boxService() {
  return (
      <div className={styles.boxServiceContainer}>
        <Link to='/business-plan'>
          <div className={styles.boxDv}>
            <IoOptionsOutline className={styles.iconService} />
            <h6>BUSINESS PLAN</h6>
          </div>
        </Link>

          <Link to='/avaliacao-de-empresas'>
            <div className={styles.boxDv}>
              <IoCellularOutline className={styles.iconService} />
              <h6>AVALIAÇÃO DE EMPRESAS</h6>
            </div>
          </Link>

          <Link to='/fusoes-e-aquisicoes'>
          <div className={styles.boxDv}>
            <IoWalletOutline className={styles.iconService} />
            <h6>CONSULTORIA EM M&A</h6>
          </div>
          </Link>

          <Link to='/consultoria-empresarial'>
            <div className={styles.boxDv}>
              <IoChevronUpCircleOutline className={styles.iconService} />
              <h6>CONSULTORIA EMPRESARIAL</h6>
            </div>
          </Link>

          <Link to='/consultoria-em-planejamento-estrategico'>
          <div className={styles.boxDv}>
            <IoTrendingUp className={styles.iconService} />
            <h6>PLANEJAMENTO ESTRATÉGICO</h6>
          </div>
          </Link>

          <Link to='/pesquisa-de-mercado'>
            <div className={styles.boxDv}>
              <IoPieChartOutline className={styles.iconService} />
              <h6>PESQUISA DE MERCADO</h6>
            </div>
          </Link>
      </div>
  );
}

export default boxService;