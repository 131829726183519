import React from 'react';

import styles from './titleHeaderBox.module.css';

function titleHeaderBox({title, subTitle, description}) {
  return (
      <div className={styles.titleHeaderBoxContainer}>
        {subTitle && <h5>{subTitle}</h5>}
        {title && <h1>{title}</h1>}
        {description && <p>{description}</p>}
      </div>
  );
}

export default titleHeaderBox;