import React from 'react';
import classNames from 'classnames';
import { useIntl } from "gatsby-plugin-intl";
import { graphql } from "gatsby";

import '../styles/index.css';
import styles from '../styles/home.module.css';
import TitleHeaderBox from "../components/titleHeaderBox";
import BoxService from "../components/boxService";

import Layout from "../components/layout";

const IndexPage = ({ data }) => {

  const intl = useIntl();
  data = data.allStrapiPaginaInicial.edges[0].node;

  const tituloMenor = intl.locale === 'en' && data.tituloMenorEn ? data.tituloMenorEn : data.tituloMenorPt;
  const tituloMaior = intl.locale === 'en' && data.tituloMaiorEn ? data.tituloMaiorEn : data.tituloMaiorPt;
  const subtitulo = intl.locale === 'en' && data.subtituloEn ? data.subtituloEn : data.subtituloPt;
  const textoProposito = intl.locale === 'en' && data.textoPropositoEn ? data.textoPropositoEn : data.textoPropositoPt;

  return (
    <Layout titlePage="Home">

      <div className={styles.bannerHomeWide}>
        {data.banners.map(({ tituloPt, descricaoPt, tituloEn, descricaoEn, imagem }, index) => (
          <div key={index} className={styles.headerContainer}>
            <img id={styles.banner} src={imagem.localFile.publicURL} alt="" />

            <div id={styles.boxDescriptionBanner}>
              <h1 className={styles.title}>{intl.locale === 'en' && tituloEn ? tituloEn : tituloPt}</h1>
              <p className={styles.description}>{intl.locale === 'en' && descricaoEn ? descricaoEn : descricaoPt}</p>
            </div>
          </div>
        ))}
      </div>

      <div className={classNames("container", styles.homeContainer)}>
        <main>
          <div id={styles.homeServiceContainer} >
            <TitleHeaderBox
              subTitle={intl.locale === 'en' ? "WHAT WE DO" : "O QUE FAZEMOS"}
              title={intl.locale === 'en' ? "Services" : "Serviços"}
            />

            <BoxService />
          </div>

          <div id={styles.homeImageTextContainer} >
            <TitleHeaderBox
              subTitle={tituloMenor}
              title={tituloMaior}
              description={subtitulo}
            />

            <div className={styles.boxDivisionImgTxt}>
              <section className={styles.sectionTxt}>
                <h5>{intl.locale === 'en' ? "OUR PURPOSE" : "NOSSO PROPÓSITO"}</h5>
                <p>{textoProposito}</p>
              </section>

              <img src={data.imagemProposito.localFile.publicURL} alt={data.imagemProposito.name} id={styles.imageTxt} />
            </div>
            {/* Link do Whatsapp */}
            <div className="whatsAppLogo">
                <a href="https://wa.me/5511976282854" title="Whatsapp" className='wppFixed' >
                    <img src="/wpp.svg" alt="" />
                </a>
            </div>
          </div>
        </main>

      </div>
    </Layout>
  );
};

export const query = graphql`
query{
  allStrapiPaginaInicial {
    edges {
      node {
        tituloMenorPt
        tituloMenorEn
				tituloMaiorPt
				tituloMaiorEn
        subtituloPt
        subtituloEn
        banners {
          tituloPt
          tituloEn
          descricaoPt
          descricaoEn
          imagem {
            localFile {
              publicURL
            }
          }
        }
        textoPropositoPt
        textoPropositoEn
        imagemProposito {
          name
          localFile {
            publicURL
          }
        }
      }
    }
  }
}
`;

export default IndexPage;
